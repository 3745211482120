/* -------------------------------------------------------------------------- */
/*                                 GLOBAL VARS                                */
/* -------------------------------------------------------------------------- */

html {
  overflow-x: hidden;
  min-height: 100vh
}

:root {
  --primary: #29DDA7;
  --primaryDark: #073729;
  --primaryLight: #fefefe;
  --secondary: #03dac6;
  --secondaryDark: #018786;
  --secondaryLight: #03dac6;
  --backgroundLight: #fefefe;
  --grey: #808080;
  --lightGrey: #d3d3d3;
  --lightGreyx2: #d3d3d350;
  --black: #000000;
  --pastelRed: #F16061;
  --white: #ffffff;
}

body {
  overflow-x: hidden;
  min-height: 100vh
}

#root{
  min-height: 100vh
}

/* -------------------------------------------------------------------------- */
/*                                 BASE UTILS                                 */
/* -------------------------------------------------------------------------- */

.primaryBackground {
  background: var(--primary);
}

.borderWhite {
  border: 1px solid var(--white);
}

.highlightText {
  position: relative;
  font-weight: 600;
  color: var(--primaryDark);
}

.highlightText::after {
  content: '';
  position: absolute;
  width: 110%;
  bottom: 0;
  z-index: -1;
  left: -5%;
  height: 4px;
  background: var(--primary);
}

/* -------------------------------------------------------------------------- */
/*                                   BUTTONS                                  */
/* -------------------------------------------------------------------------- */

.tealButton {
  background: var(--primary);
  color: var(--white);
  border: 1px solid var(--primary);
  transition: all 0.3s ease-in-out;
  font-weight: 500;
}
.tealButton:hover {
  background: var(--white);
  color: var(--primary);
  border: 1px solid var(--primary);
  transition: all 0.3s ease-in-out;
  font-weight: 500;
}

.transButtonOnTeal {
  background: var(--white);
  color: var(--primary);
  border: 1px solid var(--white);
  transition: all 0.3s ease-in-out;
  font-weight: 500;
}
.transButtonOnTeal.large {
  font-size: 1.2rem;
}

.transButtonOnTeal.clear {
  background: transparent;
  color: var(--white);
}

.transButtonOnTeal:hover {
  background: var(--white);
  color: var(--primary);
}

.transButtonOnTeal.disabled:hover {
  background: transparent;
  color: var(--white);
}

.transButtonOnTeal.filledButton:hover {
    background: linear-gradient(90deg, var(--pastelRed) 50%, var(--primary) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    border-color: transparent;
    color: var(--white);
    transition: all 0.6s ease;
    animation: slide 0.3s ease-in-out forwards;
}

a.hoverAnim {
  width: max-content;
  background-color: transparent;
  border-radius: 30px;
  transition: ease-in-out 0.3s;
  box-shadow: #00000070 1px 1px 20px -5px;
}

a.hoverAnim:hover {
  transform: translateY(-5px);
  box-shadow: #00000080 1px 1px 10px -3px;
}

@keyframes slide {
    0% {
        background-position: right bottom;
    }
    100% {
        background-position: left bottom;
    }
}

button {
  border-radius: 15px;
}

button:focus {
  outline: var(--primaryDark) auto 2px;
}

/* -------------------------------------------------------------------------- */
/*                                    FONTS                                   */
/* -------------------------------------------------------------------------- */
h1, h2, h3, h4, h5, h6, p, a, span, button {
  font-family: 'poppins', sans-serif;
}

h1 {
  font-size: 2.5rem;
  font-weight: 900;
}

.homeFeatureRow h2, #faqs h2 {
  font-size: 2.5rem;
  font-weight: 900;
  color: var(--primaryDark);
}

.homeFeatureRow p {
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--primaryDark);
}

ul {
  padding-left: 20px;
  list-style-type: disc;
}
/* -------------------------------------------------------------------------- */
/*                                 UI ELEMENTS                                */
/* -------------------------------------------------------------------------- */

.nav-link {
  position: relative;
  padding: 3px 5px;
}

.nav-link::after {
  content: '';
  position: absolute;
  border-bottom: 4px solid var(--white);
  bottom: -4px;
  left: 0;
  width: 0%;
  height: 2px;
  background: var(--primary);
  transition: width 0.3s ease-in-out;
}

.nav-link:hover::after, .nav-link.active::after {
  width: 100%;
}

/* .btn {
  background: red;
} */

footer {
  background: var(--primaryDark);
  color: var(--white);
}
.faq {
  cursor: pointer;
  border-bottom: 1px solid var(--lightGrey);
}

.faq h3 {
  font-size: 1.2rem;
  font-weight: 500;
}
/* -------------------------------------------------------------------------- */
/*                                    FORMS                                   */
/* -------------------------------------------------------------------------- */

.emailInput.onTealBg {
  background: transparent;
  border-bottom: 1px solid var(--white);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  transition: all 0.3s ease-in-out;
  color: var(--white);
  font-weight: 500;
}

.emailInput.onTealBg::placeholder {
  color: #ffffff90;
}

.emailInput.onTealBg:focus {
  outline: none;
  border: 1px solid var(--white);
  border-radius: 15px;
}

.formError {
  color: var(--pastelRed);
  font-size: 0.9rem;
}

#submittedFormContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 15px;
  background: var(--primaryDark);
  color: var(--white);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}


/* -------------------------------------------------------------------------- */
/*                                 ANIMATIONS                                 */
/* -------------------------------------------------------------------------- */



/* -------------------------------------------------------------------------- */
/*                              RESPONSIVE DESIGN                             */
/* -------------------------------------------------------------------------- */
@media screen and (max-width: 468px) {
  h1 {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  .homeFeatureRow h2, #faqs h2{
    font-size: 1.6rem;
    line-height: 2.2rem;
  }

  .homeFeatureRow p, p {
    font-size: 0.9rem;
    /* line-height: 2.4rem; */
  }

  h3 {
    font-size: 1.1rem!important;
  }

}

@media screen and (min-width: 469px) and (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }

}

@media screen and (min-width: 1201px) {
  .center-on-large>div {
    padding-left: 100px;
    padding-right: 100px;
  }
}